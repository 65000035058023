import React, { useState, useEffect, useRef } from "react";
import ChatMessages from "./chat";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import Longin from "./login";

function Contents(props) {
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState("none");

  
  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      setDisplay('block')
    }
    if(localStorage.getItem('refreshToken')!=null){
      let url = "https://api.zaojingyoutu.top/api/refresh/";
  
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh: localStorage.getItem('refreshToken')
          }),
      };
  
      fetch(url, options)
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => {
                localStorage.setItem('token', data.access_token);
                
              });
            } else {
              alert("登录失败");
              setDisplay('block')
            }
          })
    }
  }, []); 
  const handleMessage = (message) => {
    setData(prevData => {
      if (message.index === 1 || message.sender === "human") {
        return [...prevData, message];
      } else {
        return [...(prevData.slice(0, prevData.length - 1)), message];
      }
    });
  };
  const divRef = useRef(null);
  const clear = () => {
    setData([]);
  }

  useEffect(() => {
    divRef.current.scrollTo(0, divRef.current.scrollHeight);
  }, [data]);
  let width = "50%";
  let left = "25%";
  let height = "75vh";
  if (window.matchMedia('(max-width: 600px)').matches) {
    width = "95%";
    left = "1%";
    height = "71vh";
  }
  if (
    navigator.userAgent.match(
      /(iPhone|iPad|iPod|Android|Windows Phone|Mobile|BlackBerry|Palm|Tablet|iPad)/
    )
  ) {
    width = "95%";
    left = "0%";
    height = "65vh";
  }

  // 点击事件处理函数
  const copyToClipboard = (replacedText) => {
    navigator.clipboard.writeText(replacedText);
  };
  const handleLogin = (display) => {
    setDisplay(display);
  };

  return (
    <div>
      <div

        style={{
          height: height,
          overflow: "hidden",
          padding: "10px",
          backgroundColor: "white",
          fontSize: "medium",
          width: width,
          left: left,
          position: "relative",
          borderRadius: "10px",
        }}
      >
        <div ref={divRef} style={{ height: "100%", overflowY: "auto", }} >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            什么都可以问我哦！
          </p>
          {data.map((item) => {
            let replacedText = item.text;
            if (item.sender === "human") {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "15px",
                  }}
                >
                  <div
                    style={{
                      background: "#dfd6c8",
                      maxWidth: "100%",
                      wordBreak: "break-all",
                      padding: "2px 5px",
                      borderRadius: "10px",
                      display: "inline-block",
                      margin: 0,
                    }}
                  >
                    <ReactMarkdown
                      children={replacedText}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || "");
                          return !inline && match ? (
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, "")}
                              style={dark}
                              language={match[1]}
                              PreTag="div"
                              {...props}
                            />
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          );
                        },
                      }}
                    ></ReactMarkdown>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    background: "#e6e8eb",
                    wordBreak: "break-all",
                    padding: "2px 5px",
                    borderRadius: "10px",
                    margin: 0,
                  }}
                >
                  <div style={{ right: "0%", paddingRight: '14px', height: 0, display: 'flex', flexDirection: 'row-reverse', }}>
                    <button
                      id="copyBtn"
                      onClick={() => copyToClipboard(replacedText)}
                      style={{ cursor: 'pointer', color: 'brown', height: '20PX' }}
                    >
                      复制
                    </button>
                  </div>
                  <ReactMarkdown
                    children={replacedText}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <SyntaxHighlighter
                            children={String(children).replace(/\n$/, "")}
                            style={dark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          />
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  ></ReactMarkdown>
                </div>
              );
            }
          })}
        </div>
        
      </div>
      <ChatMessages
        onMessage={handleMessage}
        handleLogin={handleLogin}
        clear={clear}
      ></ChatMessages>
      <div style={{ display: display }}>
        <Longin handleLogin={handleLogin}></Longin>
      </div>
    </div>
  );
}
export default Contents;
